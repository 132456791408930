import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'

import Logo from '../../images/logo-nmf.png'
import Footer from '../../components/footer';

const Terms = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <Fragment>
            <Navbar2 Logo={Logo} />
            <PageTitle pageTitle={'Terms and Conditions'} />
            <section className="wpo-service-single-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-content">
                                    <div className="wpo-service-single-content-des">
                                        <p>
                                            We Nimal Maula Foundation a, duly incorporated under the Companies Act 2013, have the
                                            registered address at “ 404 Konark Towers, Dilsukhnagar, Hyderabad-500036,
                                            Telangana” (hereinafter referred to as “Company”) and are operating business under the
                                            brand name “Nimal Maula Foundation” (hereinafter referred to as “Brand Name”). This
                                            Terms of Service (hereinafter referred to as “Terms of Service”) is our commitment towards
                                            your trust and we intend to facilitate Services (defined later) through our Website. This
                                            Terms of Service lays down all the terms and conditions surrounding the usage of our
                                            Website “www.nimalmaulafoundation.org” by You.
                                        </p>
                                        <p>
                                            The act of using, registering, or perusing our Website establishes a contractual agreement
                                            between the user and our organization, as outlined in our Terms of Service and other
                                            pertinent policies associated with our Website. This agreement serves all contractual, legal,
                                            and compliance purposes. The present Terms of Service hold legal validity and are regarded
                                            as an electronic record under the Information Technology Act, 2000 and its relevant
                                            regulations, as well as the revised provisions concerning electronic records in several statutes,
                                            as amended by the Information Technology Act, 2000. The computer system generates this
                                            electronic record, obviating the need for any physical or digital signature.
                                        </p>
                                        <ol>
                                            <li>Definition and Interpretation
                                                <ol>
                                                    <li>Definition
                                                        <ol>
                                                            <li>Applicable Laws- pertains to all the legal provisions,
                                                                regulations, statutes, ordinances, by-laws, and any subsequent
                                                                amendments that are enforceable within the geographical boundaries
                                                                of India.</li>
                                                            <li>Content- pertains to all the textual material, advertisements,
                                                                graphics, User interfaces, visual interfaces, photographs, trademarks,
                                                                logos, brand names, descriptions, sounds, music, and artwork.</li>
                                                            <li>Third Parties- pertains to all legal entities, whether individual
                                                                or corporate, that are associated with the Website, with the exception
                                                                of the Users and the Website's owners.</li>
                                                            <li>Services- pertains to all the provisions offered to the User
                                                                through the Website.</li>
                                                            <li>User- refers to individuals who avail the services and
                                                                experience offered by us.</li>
                                                        </ol>
                                                    </li>
                                                    <li>Interpretation
                                                        <ol>
                                                            <li>Company – for the purposes of this Terms of Service, wherever
                                                                the context so requires, “We”, “Our”, and “Us” shall mean and refer
                                                                to the Company and its owned Website.</li>
                                                            <li>User- for the purposes of this Terms of Service, wherever the
                                                                context so requires, “You”, “Your”, “Yourself” and “User”, shall
                                                                mean and refer to natural and legal individuals who shall be users of
                                                                this Website provided by Us and who is competent to enter into
                                                                binding contracts, as per law.</li>
                                                            <li>The headings of each section in these Terms of Service are only
                                                                for the purpose of organizing the various provisions under these
                                                                Terms of Service in an orderly manner and shall not be used by you
                                                                to interpret the provisions contained herein in a manner as may apply
                                                                to you. Further, it is specifically agreed by you that the headings shall
                                                                not have legal or contractual value on your usage of the Website.</li>
                                                            <li>The Parties shall refer and mean the Company and User
                                                                together, for the purpose of interpretation of terms of this Terms of
                                                                Service.</li>
                                                            <li>The use of this Website is solely governed by these Terms of
                                                                Service, Privacy Policy, and any other relevant policies as updated on
                                                                the Website and any modifications or amendments made thereto by
                                                                us from time to time, at our sole discretion. If you as a User continue
                                                                to access and use this Website, you are agreeing to comply with and
                                                                be bound by the following Terms of Service, Privacy Policy, and any
                                                                other applicable policies, thereof.</li>
                                                            <li>You expressly agree and acknowledge that these Terms of
                                                                Service and Privacy Policy, and any other applicable policies, are co-
                                                                terminus in nature and that the expiry/termination of either one will
                                                                lead to the termination of the other.</li>
                                                            <li>You unequivocally agree that these Terms of Service, Privacy
                                                                Policy, and any other applicable policies, constitute a legally binding
                                                                agreement between us and that you shall be subject to the rules,
                                                                guidelines, policies, terms, and conditions applicable to your use of
                                                                the Website, and that the same shall be deemed to be incorporated
                                                                into these Terms of Service and shall be treated as part and parcel of
                                                                the same. You acknowledge and agree that no signature or express
                                                                act is required to make these Terms of Service and the Privacy Policy
                                                                binding on you and that your act of visiting/browsing any part of the
                                                                Website constitutes your full and final acceptance of these Terms of
                                                                Service, Privacy Policy, and any other applicable policies.</li>
                                                            <li>We reserve the sole and exclusive right to amend or modify
                                                                these Terms of Service without any prior permission or intimation to
                                                                you, and you expressly agree that any such amendments or
                                                                modifications shall come into effect immediately. If you do not
                                                                adhere to the changes, you must stop using the Website at once. Your
                                                                continued use of the Website will signify your acceptance of the
                                                                changed Terms of Service.</li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        <h5>Website Overview</h5>
                                        <p>Welcome to the Nimal Maula Foundation, a non-profit organization dedicated to
                                            transforming lives through financial empowerment and promoting riba (interest)-free
                                            transactions within our community. Our mission is simple yet profound: to provide
                                            individuals and families with the knowledge and tools they need to navigate the
                                            complex world of finance while upholding ethical principles. Here, we firmly believe
                                            that everyone should have access to fair and ethical financial solutions. Our mission is

                                            to break the chains of riba (interest) that often burden individuals and communities,
                                            limiting their financial freedom and well-being. Through our expertise and
                                            unwavering commitment, we strive to empower individuals, ensuring they have the
                                            skills and resources to make informed financial decisions that benefit themselves and
                                            their communities.
                                        </p>
                                        <h5>Services on the Website</h5>
                                        <p>The Website provides a platform offering services including Islamic Finance
                                            Education, Donation Collection, Debt Relief, Interest-Free Microloans details for
                                            which can be found on our platform.</p>
                                        <h5>Compliance</h5>
                                        <p>This Terms of Service is made in accordance with the provisions of the Information
                                            and Technology Act, 2000 and the rules therein along with the amendment from time
                                            to time. This Terms of Service is published on the Website in accordance with the
                                            provisions of Rule 3 (1) of the Information Technology (Intermediaries
                                            guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable
                                            Security Practices and Procedures and Sensitive Personal Data or Information)
                                            Rules, 2011 of Information Technology Act, 2000 amended through Information
                                            Technology Amendment Act, 2008 that require publishing the Terms of Service and
                                            practices for access and usage of any functional Website.
                                        </p>
                                        <h5>Registration</h5>
                                        <p className='mb-2'>Registration on the Website is not mandatory for Users of the Website. The Users can
                                            register by providing the following information:
                                        </p>
                                        <ol>
                                            <li>Name</li>
                                            <li>Mobile Number</li>
                                            <li>Email ID</li>
                                            <li>Sex</li>
                                            <li>Location</li>
                                            <li>File Access</li>
                                            <li>Log Information</li>
                                        </ol>
                                        <p>The registration process allows for user authentication through one-time password (OTP)
                                            verification.
                                        </p>
                                        <h5>Operations</h5>
                                        <ol>
                                            <li>
                                                The Company retains the prerogative to alter, suspend, or discontinue the Website or
                                                any of its components, including features and functionalities, without prior
                                                notification, at any given time.
                                            </li>
                                            <li>
                                                In the circumstance of any alteration, cessation, or conclusion, the aforementioned
                                                Termination Clause shall persist in its applicability.
                                            </li>
                                        </ol>
                                        <h5>Eligibility</h5>
                                        <ol>
                                            <li>
                                                You represent and warrant that you are competent and eligible to enter into legally
                                                binding agreements and of competent age and that you have the requisite authority
                                                to bind yourself/themselves to these Terms of Service as per the Applicable Law.
                                                However, if you are a minor using this Website, you may do so with the consent of
                                                your legal guardian. All Acts of the minor shall be deemed to be considered as acts
                                                undertaken under the supervision of their legal guardian.
                                            </li>
                                            <li>
                                                You further represent that you will comply with these Terms of Service and all
                                                applicable local, state, national, and international laws, rules, and regulations.
                                            </li>
                                            <li>
                                                You shall not use the Website if you are not competent to enter into a contract or are
                                                disqualified from doing so by any other Applicable Laws, rules, or regulations,
                                                currently in force.
                                            </li>
                                        </ol>
                                        <h5>Payment Gateway</h5>
                                        <p>“Razorpay,” and “PayG” have been used as third-party payment gateway attached to this
                                            platform. The Customers shall be provided with payment-related information through the
                                            Platform. No payment-related information like, and not limited to, Credit Card numbers,
                                            Debit Card numbers, UPI IDs, Bank Account Details are stored or managed by the Platform
                                            and We do not hold any liability towards any liability arising herein.
                                        </p>
                                        <h5>Content</h5>
                                        <ol>
                                            <li>
                                                All the Content displayed on the Website is subject to copyright and shall not be
                                                reused by You (or a Third-Party) without prior written consent from the Company
                                                and the copyright owner.
                                            </li>
                                            <li>
                                                You are solely responsible for the integrity, authenticity, quality, and/or genuineness
                                                of the Content provided by you on the Website and whilst feedback and comments
                                                by You can be made via the Website, we bear no liability whatsoever for any
                                                feedback or comments made by the other Users or made in respect of any of the
                                                Content on the Website. Further, the Company reserves its right to suspend the
                                                account of any User for an indefinite period to be decided at the discretion of the
                                                Company or to terminate the account of any User who is found to have created or
                                                shared or submitted any Content or part thereof that is found to be untrue/ inaccurate/
                                                misleading/ offensive/ vulgar. You shall be solely responsible for making good any
                                                financial or legal losses incurred through the creation/ sharing/ submission of Content
                                                or part thereof that is deemed to be untrue/ inaccurate/ misleading/ offensive/ vulgar.
                                            </li>
                                            <li>
                                                You have a personal, non-exclusive, non-transferable, revocable, limited privilege to
                                                access the Content on the Website. You shall not copy, adapt, or modify any Content
                                                without written permission from the Company.
                                            </li>
                                        </ol>
                                        <h5>Indemnity</h5>
                                        <p>
                                            The User consents to indemnify while preserving Us, along with our directors,
                                            officers, employees, and agents (collectively referred to as &quot;Parties&quot;), from any losses,
                                            liabilities, claims, damages, demands, costs, and expenses (including legal fees and
                                            disbursements in connection therewith and interest chargeable thereon) that may be
                                            asserted against or incurred by Us due to a breach or non-performance of any
                                            representation, warranty, covenant, or agreement made or obligation to be performed
                                            by the User according to these Terms of Service. Moreover, you consent to indemnify
                                            the Company from any allegations brought forth by any external party as a result of,
                                            or in relation to, the following:
                                        </p>
                                        <ol>
                                            <li>The manner in which you utilize the Website;</li>
                                            <li>Your breach of the provisions outlined in these Terms of Service;</li>
                                            <li>Your infringement upon the rights of other Parties, Users, and/or Third-Party entities;</li>
                                            <li>Your purported inappropriate behavior in accordance with these Terms of Service; and</li>
                                            <li>Your actions in relation to the Website.</li>
                                        </ol>
                                        <p>
                                            The User consents to provide complete cooperation in the process of indemnifying the
                                            Company, bearing the expenses themselves. It is also expected that you will refrain
                                            from reaching a settlement with any other party involved in the matter without
                                            obtaining prior consent from the Company.
                                            The Company shall not be held responsible for compensating the User or any third
                                            party for any damages, including but not limited to loss of profits, data, or use, that
                                            may result from the use of the Website&#39;s services or materials. This includes damages
                                            that may be considered special, incidental, indirect, consequential, or punitive,
                                            regardless of whether they were foreseeable or whether the User had been advised of
                                            the possibility of such damages. The Company cannot be held liable based on any
                                            theory of liability, including breach of contract or warranty, negligence, or other
                                            tortuous action, or any other claim arising from the user&#39;s use of or access to the
                                            Website&#39;s services or materials.
                                        </p>
                                        <h5>Limitation of Liability</h5>
                                        <p>
                                            We shall not be deemed responsible for any possible damages, whether direct or
                                            indirect, that may arise from the use of the services. Such damages may include any
                                            direct, indirect, incidental, special, consequential, or exemplary damages, including
                                            but not limited to, damages for loss of profits, data, or other intangible losses,
                                            resulting from:
                                        </p>
                                        <ol>
                                            <li>the ability or inability to use the Website or services;</li>
                                            <li>unauthorized access or alteration of user data;</li>
                                            <li>the conduct or content of third parties on the Website;</li>
                                            <li>errors or omissions in the Website&#39;s content;</li>
                                            <li>interruptions or cessation of transmission to or from the Website;</li>
                                            <li>transmission of harmful code such as bugs, viruses, Trojan horses, or other malware.</li>
                                        </ol>
                                        <p>The limitation of liability is a legal provision that restricts the amount of damages that
                                            a party can be held liable for in the event of a breach of contract or other legal claims.

                                            Nimal Maula Foundation, along with its officers, directors, employees, agents, or
                                            affiliates, cannot be held accountable for any damages, liabilities, costs, or losses that

                                            may arise from your utilization of the our Website or services. This liability
                                            exemption applies regardless of whether the damages are based on warranty, contract,
                                            tort, or any other legal theory, and even if you were informed of the potential for such
                                            damages.</p>
                                        <h5>Termination</h5>
                                        <ol>
                                            <li>
                                                Termination by User: Users can terminate their account created on our
                                                platform by using the account deletion process on the Website or by sending a
                                                “request for deletion” mail to “talktous@nimalmaulafoundation.org”
                                            </li>
                                            <li>
                                                Termination by the Company: The Company reserves the right to terminate or
                                                suspend the user’s access to the Website, in whole or in part, without prior notice, for
                                                any reason including, but not limited to, violation of these terms and conditions or
                                                any applicable laws or regulations. In the event of termination by the Company, users
                                                will no longer have access to the account and any data associated with it.
                                            </li>
                                            <li>
                                                Reinstatement of Account: If your access to the Website has been terminated
                                                by either party, you may request reinstatement of your account by contacting the
                                                Company. The Company reserves the right to accept or reject such reinstatement
                                                request in its sole discretion.
                                            </li>
                                        </ol>
                                        <p>
                                            The Company reserves the right to modify, suspend, or terminate the Website or any
                                            part thereof, including any features or functionalities, at any time without prior notice.
                                            In the event of such modification, suspension, or termination, this Termination Clause
                                            shall continue to apply.
                                        </p>
                                        <h5>Communication</h5>
                                        <p>By using this Website and providing your identity and contact information to the
                                            Company through the Website, you agree and consent to receive e-mails or SMS
                                            from us and/or any of its representatives at any time.
                                            You can report to “talktous@nimalmaulafoundation.org” if you find any
                                            discrepancy with regard to Website or Content-related information and we will take

                                            necessary action after an investigation. The response with resolution (if any issues
                                            found) shall be dependent on the time is taken for investigation.
                                            You expressly agree that notwithstanding anything contained hereinabove, it may be
                                            contacted by us relating to any services availed by you on the Website or anything
                                            pursuant thereto and you agree to indemnify us from any harassment claims. It is
                                            expressly agreed to by us that any information shared by us shall be governed by the
                                            Privacy Policy.
                                        </p>
                                        <h5>User obligations and formal undertakings to conduct</h5>
                                        <p>You agree and acknowledge that you are a restricted user of this Website and you:</p>
                                        <ol>
                                            <li>agree to provide genuine credentials during the process whenever required on
                                                the Website. You shall not use a fictitious identity. We are not liable if you have
                                                provided incorrect information;
                                            </li>
                                            <li>
                                                agree that you shall be solely responsible for updating the correct information
                                                on the Website in the case that any information provided to the Website is changed or
                                                altered
                                            </li>
                                            <li>
                                                agree that you are solely responsible for maintaining the confidentiality of
                                                your account. You agree to notify us immediately of any unauthorized use of your
                                                account. We reserve the right to close your account at any time for any or no reason;
                                            </li>
                                            <li>
                                                understand and acknowledge that the data submitted is manually entered into
                                                the database of the Website. You also acknowledge the fact that data so entered into
                                                the database is for easy and ready reference for you, and to streamline the services
                                                through the Website;
                                            </li>
                                            <li>
                                                authorize the Website to use, store, or otherwise process certain personal
                                                information and all published Content, responses, locations, User comments, reviews
                                                and ratings for personalization of service, marketing and promotional purposes, and
                                                for optimization of User-related options and service;
                                            </li>
                                            <li>
                                                understand and agree that, to the fullest extent permissible by law, the Website
                                                or any of its affiliates or their respective officers, directors, employees, agents,
                                                licensors, representatives, operational service providers, advertisers or suppliers shall
                                                not be liable for any loss or damage, of any kind, direct or indirect, in connection
                                                with or arising from the use of the Website or this Terms of Service, including, but
                                                not limited to compensatory, consequential, incidental, indirect, special or punitive
                                                damages;
                                            </li>
                                            <li>
                                                are bound not to cut, copy, modify, recreate, reverse engineer, distribute,
                                                disseminate, post, publish or create derivative works from, transfer, or sell any
                                                information obtained from the Website. Any such use/limited use of the Website will
                                                only be allowed with the prior express written permission;
                                            </li>
                                            <li>
                                                agree not to access (or attempt to access) the Website and/or the materials or
                                                Service by any means other than through the interface provided by the Website. The
                                                use of deep-link, robot, spider or other automatic devices, program, algorithm or
                                                methodology, or any similar or equivalent manual process, to access, acquire, copy
                                                or monitor any portion of the Website or its Content, or in any way reproduce or
                                                circumvent the navigational structure or presentation of the Website, materials or any
                                                Content, or to obtain or attempt to obtain any materials, documents or information
                                                through any means not specifically made available through the Website will lead to
                                                suspension or termination of your access to the Website. We disclaim any liabilities
                                                arising concerning such offensive Content on the Website.
                                            </li>
                                            <p className='mt-4'>You further undertake not to:</p>
                                            <li>
                                                engage in any activity that interferes with or disrupts access to the Website or
                                                the Services provided therein (or the servers and networks which are connected to the
                                                Website);
                                            </li>
                                            <li>
                                                impersonate any person or entity, or falsely state or otherwise misrepresent
                                                his/her affiliation with a person or entity;
                                            </li>
                                            <li>
                                                probe, scan, or test the vulnerability of the Website or any network connected
                                                to the Website, nor breach the security or authentication measures on the Website or
                                                any network connected to the Website. The User may not reverse look-up, trace, or
                                                seek to trace any information relating to any other User of, or visitor to the Website,
                                                or any other viewer of the Website, including any User account maintained on the
                                                Website not operated/managed by the User, or exploit the Website or information
                                                made available or offered by or through the Website, in any manner;
                                            </li>
                                            <li>
                                                disrupt or interfere with the security of, or otherwise cause harm to the
                                                Website, systems resources, accounts, passwords, servers, or networks connected to
                                                or accessible through the Website or any affiliated or linked Website;
                                            </li>
                                            <li>
                                                use the Website or any material or Content therein for any purpose that is
                                                unlawful or prohibited by these Terms of Service, or to solicit the performance of any

                                                illegal activity or other activity which infringes the rights of this Website or any other
                                                Third-Party(ies);
                                            </li>
                                            <li>
                                                violate any code of conduct or guideline which may apply for or to any
                                                particular Service offered through the Website;
                                            </li>
                                            <li>
                                                violate any applicable laws, rules, or regulations currently in force within or
                                                outside India;
                                            </li>
                                            <li>
                                                violate any portion of these Terms of Service or the Privacy Policy, including
                                                but not limited to any applicable additional terms and conditions of the Website
                                                contained herein or elsewhere, whether made by amendment, modification or
                                                otherwise;
                                            </li>
                                            <li>
                                                commit any act that causes the Company to lose (in whole or in part) the
                                                Services of its Internet Establishment (&quot;ISP&quot;) or in any manner disrupts the Services
                                                of any other supplier/service provider of the Website;
                                            </li>
                                            <li>
                                                you hereby expressly authorize the Company to disclose any and all
                                                information relating to you in our possession to law enforcement or other
                                                government officials, as we may in our sole discretion, believe necessary or
                                                appropriate in connection with the investigation and/or resolution of possible crimes,
                                                especially those involving personal injury and theft/infringement of intellectual
                                                property. You further understand that the Website might be directed to disclose any
                                                information (including the identity of persons providing information or materials on
                                                the Website) as necessary to satisfy any judicial order, Applicable Law, regulation or
                                                valid governmental request;
                                            </li>
                                            <li>
                                                you agree to use the Services provided by the Company, our affiliates,
                                                consultants and contracted companies, for lawful purposes only;
                                            </li>
                                            <li>
                                                you agree to provide authentic and true information. We reserve the right to
                                                confirm and validate the information and other details provided by you at any point
                                                in time. If upon confirmation such details are found to be false, not to be true (wholly
                                                or partly), we shall in our sole discretion reject the service order and debar you from
                                                using the Website without prior intimation whatsoever;
                                            </li>
                                            <li>
                                                you agree not to post any material on the Website that is defamatory,
                                                offensive, obscene, indecent, abusive, or needlessly distressful, or advertise any
                                                goods or Products. More specifically, you agree not to host, display, upload, update,
                                                publish, modify, transmit, or in any manner share any information that:
                                            </li>
                                            <li>belongs to another person and to which you have no right to;</li>
                                            <li>
                                                is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                                pornographic, pedophilic, libellous, invasive of another&#39;s privacy, hateful, or racially,
                                                ethnically objectionable, disparaging, relating or encouraging money laundering or
                                                gambling, or otherwise unlawful in any manner whatever;
                                            </li>
                                            <li>
                                                is in any way harmful to minors;
                                            </li>
                                            <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                                            <li>violates any Applicable Law for the time being in force;</li>
                                            <li>deceives or misleads the addressee about the origin of such messages
                                                or communicates any information which is grossly offensive or menacing;
                                            </li>
                                            <li>abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or
                                                otherwise violate the legal rights of others;</li>
                                            <li>impersonate any person or entity, or falsely state or otherwise
                                                misrepresent your affiliation with a person or entity;</li>
                                            <li>publish, post, disseminate, any grossly harmful information, harassing,
                                                blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of
                                                another&#39;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating
                                                or encouraging money laundering or gambling, or otherwise unlawful in any manner
                                                whatever; or unlawfully threatening or unlawfully harassing including but not limited
                                                to &quot;indecent representation of women&quot; within the meaning of the Indecent
                                                Representation of Women (Prohibition) Act, 1986; and
                                            </li>
                                            <li>
                                                threatens the unity, integrity, defense, security, or sovereignty of India,
                                                friendly relations with foreign states, or public order or causes incitement to the
                                                commission of any cognizable offence or prevents investigation of any offence or is
                                                insulting any other nation.
                                            </li>
                                        </ol>
                                        <h5>Suspension of User access and activity</h5>
                                        <p>
                                            Notwithstanding other legal remedies that may be available, we may in our sole
                                            discretion, deny access and/or activity by immediately removing your access
                                            credentials either temporarily or indefinitely, or suspend/terminate your association
                                            with the Website, and/or refusing to the usage of the Website, without being required
                                            to provide you with notice or cause:
                                        </p>
                                        <ol>
                                            <li>
                                                if you are in breach of any of these Terms of Service, Privacy Policy,
                                                or any other applicable policies;
                                            </li>
                                            <li>
                                                if you have provided wrong, inaccurate, incomplete or incorrect
                                                information; and
                                            </li>
                                            <li>
                                                if your actions may cause any harm, damage or loss to the other Users
                                                or the Company, at our sole discretion.
                                            </li>
                                        </ol>
                                        <h5>Intellectual Property Rights</h5>
                                        <p>
                                            The intellectual property rights, specifically the copyright and trademark, associated
                                            with the moniker Nimal Maula Foundation are owned by Nimal Maula
                                            Foundation. The usage of the name without proper authorization is strictly
                                            prohibited.
                                        </p>
                                        <p>
                                            Unless expressly agreed to in writing, nothing contained herein shall give you a right
                                            to use any of the trade names, trademarks, service marks, logos, domain names,
                                            information, questions, answers, solutions, reports, and other distinctive brand
                                            features, save according to the provisions of these Terms of Service that shall be
                                            available on the Website. All logos, trademarks, brand names, service marks, domain
                                            names, including material, designs, and graphics created by and developed by either
                                            the Website or such other Third-Party and other distinctive brand features of the
                                            Website are the property of the Website or the respective copyright or trademark
                                            owner. Furthermore, concerning the Website, we shall be the exclusive owner of all
                                            the designs, graphics and the like, related to the Website.
                                        </p>
                                        <p>
                                            You shall not use any of the Intellectual Property displayed on the Website in any
                                            manner that is likely to cause confusion among existing or prospective users of the
                                            Website, or that in any manner disparages or discredits the Website, to be determined
                                            in the sole discretion.
                                        </p>
                                        <p>
                                            You are aware all Intellectual Property, including but not limited to copyrights,
                                            relating to said Products and Services resides with the owners, and that at no point
                                            does any such Intellectual Property stand transferred from the creators.
                                        </p>
                                        <p>
                                            You are further aware that any reproduction or infringement of the Intellectual
                                            Property of the owners by you will result in legal action being initiated against you
                                            by the respective owners of the Intellectual Property so reproduced/infringed upon. It
                                            is agreed to by you that the Contents of this section shall survive even after the
                                            termination or expiry of these Terms of Service and/or Privacy Policy.
                                        </p>
                                        <h5>Disclaimer of Warranties and Liabilities</h5>
                                        <ol>
                                            <li>
                                                You further agree and undertake that you are accessing the Website at
                                                your sole risk and that you are using the best and prudent judgment before
                                                availing of any features on the Website or accessing/using any information
                                                displayed thereon.
                                            </li>
                                            <li>
                                                You agree that any kind of information, resources, activities, or
                                                recommendations obtained/availed from the Website, written or oral, will not
                                                create any warranty and we disclaim all liabilities resulting from these.
                                            </li>
                                            <li>
                                                We do not guarantee that the features and content contained in the
                                                Website will be uninterrupted or error-free, or that the Website or its server will
                                                be free of viruses or other harmful components, and you hereby expressly accept
                                                any associated risks involved with your use of the Website.
                                            </li>
                                            <li>
                                                It is further agreed to by you that the contents of this section shall
                                                survive even after the termination or expiry of the Terms of Service and/or
                                                Privacy Policy.
                                            </li>
                                        </ol>
                                        <h5>Force Majeure</h5>
                                        <p>
                                            We will not be liable for damages for any delay or failure to perform our obligations
                                            hereunder if such delay or failure is due to cause beyond our control or without its
                                            fault or negligence, due to force majeure events including but not limited to acts of
                                            war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or
                                            dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy,
                                            cheating, illegal or unauthorized.
                                        </p>
                                        <h5>Dispute Resolution and Jurisdiction</h5>
                                        <p>
                                            In the event of any dispute arising out of or in connection with this Terms of Service,
                                            including any dispute relating to the validity of this Terms of Service, the Parties
                                            shall, at first instance, attempt to resolve the dispute by mediation administered by the
                                            Centre for Online Resolution of Disputes (“CORD”) (www.resolveoncord.com) and
                                            conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be
                                            appointed by CORD.
                                        </p>
                                        <p>
                                            In the event the Parties are unable to resolve any such dispute through mediation
                                            within 30 days of the initiation of the dispute, subject to a contrary agreement
                                            between the Parties, the dispute shall be finally resolved by arbitration administered
                                        </p>
                                        <p>
                                            by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a
                                            sole arbitrator to be appointed by CORD. The language of arbitration shall be English.
                                            The seat of arbitration shall be Hyderabad, India.
                                        </p>
                                        <p>
                                            You expressly agree that the Terms of Service, Privacy Policy and any other
                                            agreements entered into between the Parties are governed by the laws, rules, and
                                            regulations of India.
                                        </p>
                                        <h5>MISCELLANEOUS PROVISIONS</h5>
                                        <ol>
                                            <li>
                                                <b>Entire Agreement</b>: These Terms of Service, read with the Privacy
                                                Policy and Disclaimer form the complete and final contract between us with
                                                respect to the subject matter hereof and supersedes all other communications,
                                                representations, and agreements (whether oral, written, or otherwise) relating
                                                thereto.
                                            </li>
                                            <li>
                                                <b>Waiver</b>: The failure at any time to require the performance of any
                                                provision of these Terms of Service shall in no manner affect our right at a later
                                                time to enforce the same. No waiver by us of any breach of these Terms of
                                                Service, whether by conduct or otherwise, in any one or more instances, shall be
                                                deemed to be or construed as a further or continuing waiver of any such breach, or
                                                a waiver of any other breach of these Terms of Service.
                                            </li>
                                            <li>
                                                <b>Severability</b>: If any provision/clause of these Terms of Service is held
                                                to be invalid, illegal, or unenforceable by any court or authority of competent
                                                jurisdiction, the validity, legality, and enforceability of the remaining
                                                provisions/clauses of these Terms of Service shall in no way be affected or
                                                impaired thereby, and each such provision/clause of these Terms of Service shall
                                                be valid and enforceable to the fullest extent permitted by Applicable Law. In
                                                such case, these Terms of Service shall be reformed to the minimum extent
                                                necessary to correct any invalidity, illegality or unenforceability, while preserving
                                                to the maximum extent the original rights, intentions and commercial expectations
                                                of the Parties hereto, as expressed herein.
                                            </li>
                                            <li>
                                                <b>Contact Us</b>: If you have any questions about these Terms of Service,
                                                the practices of the Website, or your experience, you can contact us by emailing
                                                us at talktous@nimalmaulafoundation.org or by writing to us at:
                                                talktous@nimalmaulafoundation.org
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default Terms;
